import { Injectable } from "@angular/core";
import { AuthGuardService } from "./auth-guard.service";

@Injectable({
    providedIn: "root",
})
export class ConfigService {
    public recaptchaSiteKeyV3: string | null = null;

    constructor(
        private guard: AuthGuardService,
    ) { }

    async loadConfig() {
        const siteKeyV3 = await this.guard.getrecapchekey();
        this.recaptchaSiteKeyV3 = siteKeyV3;
    }
}