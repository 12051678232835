import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, DestroyRef, Inject, OnInit, Optional, PLATFORM_ID, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatIconModule } from '@angular/material/icon';
import { Partner } from '@app/models/partner/IPartner';
import { AuthGuardService } from '@app/services/auth-guard.service';
import { ChatService } from '@app/services/chat.service';
import { GlobalcontentService } from '@app/services/globalcontent.service';
import { SettingsService } from '@app/services/settings.service';
import { fromEvent } from 'rxjs';
import { MEDIA_TOKEN } from 'tockens';

@Component({
    selector: 'clever-overmenu',
    imports: [
        MatIconModule
    ],
    templateUrl: './overmenu.component.html',
    styleUrl: './overmenu.component.scss'
})
export class OvermenuComponent implements OnInit {
    isBrowser: boolean = false;
    destroyRef = inject(DestroyRef);
    showbtns = signal<boolean>(false);
    lastposition: number = 0;
    partner: Partner;
    small = signal<boolean>(false);

    anfpath = signal<string>('');
    contpath = signal<string>('');
    whatsapp = signal<string>('');
    showchat = signal<boolean>(false);
    constructor(
        @Inject(PLATFORM_ID) protected platformId: any,
        protected authguard: AuthGuardService,
        private settingsService: SettingsService,
        protected chatService: ChatService,
        protected mediaObserver: BreakpointObserver,
        protected cdr: ChangeDetectorRef,
        protected gservice: GlobalcontentService,
        @Optional() @Inject(MEDIA_TOKEN) private media_token: string,
    ) {
        this.isBrowser = isPlatformBrowser(this.platformId);
        this.small.set(this.media_token === 'xs');
    }

    ngOnInit(): void {
        if (this.isBrowser) {
            this.initMedia();
            this.checkPartner();
            this.initScroll();
        }
    }

    async initMedia() {
        if (this.mediaObserver.isMatched(Breakpoints.XSmall)) {
            this.small.set(true);
        }
        if (this.mediaObserver.isMatched(Breakpoints.Small)) {
            this.small.set(true);
        }
        if (this.mediaObserver.isMatched(Breakpoints.Medium)) {
            this.small.set(true);
        }
        if (this.mediaObserver.isMatched(Breakpoints.Large)) {
            this.small.set(false);
        }
        if (this.mediaObserver.isMatched(Breakpoints.XLarge)) {
            this.small.set(false);
        }
        this.cdr.detectChanges();
    }

    async checkPartner() {
        this.authguard.getPartner$.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((pp) => {
            if (pp?.partnerid?.length > 0) {
                this.partner = pp;
                this.showchat.set(!pp.hideChat);
                this.chekcAnfrageContact();
                this.getsettings();
            }
        });
    }

    async openChat() {
        this.chatService.setOpenChat();
    }

    async chekcAnfrageContact() {
        this.gservice.request({ partnerid: this.partner.partnerid }, 'checkanfragecontact').pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((res) => {
            if (res?.anfrage?.path) {
                if (res.anfrage.path !== '/') {
                    this.anfpath.set(res.anfrage.path + '/' + res.anfrage.pathname);
                } else {
                    this.anfpath.set('/' + res.anfrage.pathname);
                }
            }
            if (res?.contact?.path) {
                if (res.contact.path !== '/') {
                    this.contpath.set(res.contact.path + '/' + res.contact.pathname);
                } else {
                    this.contpath.set('/' + res.contact.pathname);
                }
            }
        });
    }

    async getsettings() {
        this.settingsService.request({ db: this.partner.partnerid, type: 'settings' }, 'getsettings').pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((res) => {
            if (!res?.error) {
                this.whatsapp.set(res?.documents?.socialnetworks?.whatsapp);
            }
        });
    }

    async initScroll() {
        fromEvent(window, 'scroll').pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((e) => {
            this.lastposition = window.scrollY;
            this.checkScrollPos();
        });
    }

    checkScrollPos(): void {
        // faire quelque chose avec la position du scroll
        if (this.lastposition >= 550) {
            this.showbtns.set(true);
        } else {
            this.showbtns.set(false);
        }
    }
}
