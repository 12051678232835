import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { LanguageService } from './language.service';
import { isPlatformBrowser } from '@angular/common';
@Injectable()
export class WebLanguageService extends LanguageService {
    constructor(@Inject(PLATFORM_ID) protected platformId: any,) {
        super();
    }

    getLanguage(defaultLanguage: string): string {
        let deviceLanguage = 'de';
        if(isPlatformBrowser(this.platformId)) {
            deviceLanguage = window.localStorage.getItem('current_language');
            if (!deviceLanguage) {
                deviceLanguage = window?.navigator.language?.substring(
                    0,
                    2
                ) as string;
            }
        }

        return this.SUPPORTED_LANGUAGES.includes(deviceLanguage) ? deviceLanguage : defaultLanguage;
    }
}
